<template>
  <div
    data-cy="toast"
    class="snackbar invisible min-w-[250px] bg-white shadow p-4 fixed z-[100000] left-1/2 -translate-x-1/2 top-[15px] border rounded max-w-[800px] flex mobileLandscape:min-w-[500px]"
    :class="{
      '!visible animate-[fadeInSnackbar_0.5s,_fadeOutSnackbar_0.5s_9.5s]': show,
      'border-etGreen-100 text-etGreen-100': success,
      'border-etRed-100 text-etRed-100': error,
      'border-etYellow-100 text-etYellow-100': warning,
      'border-etBlue-80 text-etBlue-80': info
    }"
  >
    <div
      class="w-full max-w-[50px] h-[50px] mr-4 flex items-center justify-center rounded"
      :class="{
        show,
        'bg-etGreen-20': success,
        'bg-etRed-10': error,
        'bg-etYellow-10': warning,
        'bg-etBlue-10': info
      }"
    >
      <i
        class="fas text-2xl leading-none"
        :class="{
          'fa-info': info,
          'fa-exclamation': warning,
          'fa-times': error,
          'fa-check': success
        }"
      />
    </div>
    <div>
      <template v-if="hasDynamicValues">
        <i18n-t
          class="!mb-2"
          :class="{
            show,
            'text-etGreen-100': success,
            'text-etRed-100': error,
            'text-etYellow-100': warning,
            'text-etBlue-80': info
          }"
          :keypath="title"
        ></i18n-t>
        <TextSmall>
          <i18n-t
            tag="pre"
            :keypath="message"
            class="!font-poppins break-words whitespace-pre-wrap"
          >
            <template #workspaceLink>
              <router-link :to="{ name: 'clientOverview' }">
                {{ $t('shared.labels.workspace') }}
              </router-link>
            </template>
            <template
              v-for="option in options"
              :key="option.name"
              #[option.name]
            >
              <span :class="{ 'font-bold': boldOptions }">{{
                option.value
              }}</span>
            </template>
          </i18n-t>
        </TextSmall>
      </template>
      <template v-else>
        <TitleSmall
          :title="title"
          class="!mb-2"
          :class="{
            show,
            'text-etGreen-100': success,
            'text-etRed-100': error,
            'text-etYellow-100': warning,
            'text-etBlue-80': info
          }"
        />
        <TextSmall>
          <pre class="!font-poppins break-words whitespace-pre-wrap">{{
            message
          }}</pre>
        </TextSmall>
      </template>
    </div>
    <button
      class="absolute top-[10px] right-[10px] text-etBlack-50 cursor-pointer hover:text-etBlack-80"
      data-cy="close_toast"
      @click="close"
    >
      <i class="fas fa-times" />
    </button>
  </div>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'
import TitleSmall from '@/components/shared/font/title/TitleSmall'
import i18n from '@/i18n'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const time = ref(null)

const queue = computed(() => store.state.toast.queue)
const showToast = computed(() => store.state.toast.showToast)

const type = computed(() => {
  return queue.value[0] && queue.value[0].type
})
const title = computed(() => {
  return queue.value[0] && queue.value[0].title
})
const hasDynamicValues = computed(() => {
  return queue.value[0] && queue.value[0].hasDynamicValues
})
const boldOptions = computed(() => {
  return queue.value[0] && queue.value[0].boldOptions
})
const message = computed(() => {
  return queue.value[0] && queue.value[0].message
})
const options = computed(() => {
  return queue.value[0] && queue.value[0].options
})
const show = computed(() => {
  return showToast.value
})
const success = computed(() => {
  return type.value === 'success'
})
const error = computed(() => {
  return type.value === 'error'
})
const warning = computed(() => {
  return type.value === 'warning'
})
const info = computed(() => {
  return type.value === 'info'
})

const currentTimeout = computed(() => {
  if (error.value) {
    return 10000
  } else {
    return 5000
  }
})

const closeToast = () => store.commit('toast/closeToast')
const openToast = () => store.commit('toast/openToast')

watch(
  () => queue.value.length,
  (length) => {
    if (length > 0) {
      setTimeout(() => {
        openToast()
      }, 500)
    }
  }
)

watch(
  () => show.value,
  (newValue) => {
    if (newValue) {
      time.value = setTimeout(() => {
        closeToast()
      }, currentTimeout.value)
    } else {
      clearTimeout(time.value)
    }
  }
)

function close() {
  clearTimeout(time.value)
  closeToast()
}
</script>
