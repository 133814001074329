<template>
  <fwb-button
    :id="id"
    :data-cy="id"
    :type="type"
    :disabled="isDisabled"
    :loading="loader"
    size="lg"
    class="justify-center rounded-sm focus:!ring-transparent"
    :class="[
      {
        '[&>div]:m-0': ($slots.default && !title) || loader,
        '!rounded-lg': rounded,
        '!bg-white !outline-etBlue-80 !text-etBlue-80 !border !border-etBlue-80 hover:!bg-etBlue-30':
          reversed
      },
      themes[color]
    ]"
  >
    {{ loader ? '' : title }}
    <template v-if="prefix" #prefix>
      <font-awesome-icon :icon="prefix" />
    </template>
    <template v-if="$slots.default" #suffix>
      <slot />
    </template>
  </fwb-button>
</template>
<script setup>
import { FwbButton } from 'flowbite-vue'
import { onMounted, ref, watch } from 'vue'
const props = defineProps({
  title: String,
  disabled: { type: Boolean, default: false },
  loader: { type: Boolean, default: false },
  type: String,
  reversed: {
    type: Boolean,
    default: false
  },
  id: String,
  color: {
    type: String,
    default: 'primary',
    validator(value, props) {
      // The value must match one of these strings
      return [
        'transparent',
        'primary',
        'lightBlue',
        'green',
        'lightGreen',
        'etGreen',
        'red',
        'lightRed'
      ].includes(value)
    }
  },
  rounded: Boolean,
  prefix: String
})
const isDisabled = ref(false)
const themes = ref({
  transparent: 'bg-transparent hover:bg-transparent',
  primary:
    'bg-etBlue-primary focus:ring-etBlue-30 dark:bg-etBlue-primary dark:focus:ring-etBlue-100 hover:bg-etBlue-100 dark:hover:bg-etBlue-primary',
  lightBlue:
    'bg-blue-50 text-gray-900 border border-blue-200 hover:bg-blue-100',
  green: 'bg-green-400 hover:bg-green-500 !rounded-lg',
  lightGreen:
    'bg-green-50 border border-green-300 text-gray-900 !rounded-lg hover:bg-green-100',
  etGreen: 'bg-etGreen-100 text-white hover:bg-etGreen-highlight',
  red: 'bg-red-700 text-white hover:bg-red-600',
  lightRed: 'bg-red-100 text-gray-900 border border-red-600 hover:bg-red-200'
})
onMounted(() => {
  isDisabled.value = props.disabled
})
watch(
  () => props.disabled,
  (val) => {
    isDisabled.value = val
  }
)
</script>
